<template>
  <div>
    <v-card v-if="elt !== null">
      <div class="d-flex align-center justify-space-between">
        <div>
          <v-card-title class="text-no-wrap">
            {{ title }}
          </v-card-title>
          <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
        </div>

        <v-avatar
          color="primary"
          size="48"
          class="v-avatar-light-bg primary--text me-5"
        >
          <v-icon
            size="30"
            color="primary"
          >
            {{ icons.mdiAccountBox }}
          </v-icon>
        </v-avatar>
      </div>

      <v-card-text>
        <v-list>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiAccount }}
              </v-icon>
              Nom:
            </span>
            <span
              class="text--secondary font-weight-bold"
              @click="openItem(elt)"
            >
              <v-chip pill>
                <v-avatar
                  :color=" elt.profile_picture_path ? '' : 'primary'"
                  :class=" elt.profile_picture_path ? '' : 'v-avatar-light-bg primary--text'"
                  size="32"
                  left
                >
                  <v-img
                    v-if=" elt.profile_picture_path"
                    :src="`${ elt.profile_picture_path}`"
                  ></v-img>
                  <span v-else>{{ elt.name !== null && elt.name !== undefined ? elt.name.slice(0,2).toUpperCase() : null }}</span>
                </v-avatar>
                {{ elt.civility }} {{ elt.name }}
              </v-chip>
            </span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon size="15">{{ icons.mdiSmokingOff }}</v-icon>
              Non fumeur:
            </span>
            <span class="text--secondary text-capitalize font-weight-bold">
              <v-chip
                small
                :color="elt.no_smoking ? 'primary' : 'error'"
                :class="`${ elt.no_smoking ? 'primary' : 'error'}--text`"
                class="v-chip-light-bg font-weight-bold"
              >
                {{ elt.no_smoking === true ? 'OUI' : 'NON' }}
              </v-chip>
            </span>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiPhone }}
              </v-icon>
              Tel:
            </span>
            <span class="text--secondary font-weight-bold">{{ elt.phone_number }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiEmail }}
              </v-icon>
              Email:
            </span>
            <span class="text--secondary font-weight-bold">{{ elt.email }}</span>
          </v-list-item>
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <span class="font-weight-medium me-2">
              <v-icon
                size="15"
              >
                {{ icons.mdiFileDocument }}
              </v-icon>
              Permis validé le:
            </span>
            <span class="text--secondary font-weight-bold">{{ elt.license_validated_at ? formattingDate(elt.license_validated_at) : null }}</span>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card v-else></v-card>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import {
  mdiAccount,
  mdiCarArrowLeft,
  mdiCarDoor,
  mdiCarEstate,
  mdiCarSettings,
  mdiEmail, mdiFileDocument,
  mdiPhone, mdiSmokingOff, mdiAccountBox,
} from '@mdi/js'
import { formatDate } from '@core/date/dateFunctions'
import router from '@/router'

export default {
  name: 'ReductCodeOwner',

  props: {
    user: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const elt = computed({
      get: () => props.user,
      set: value => emit('update:object', value),
    })
    const formattingDate = date => formatDate(date)
    const openItem = item => {
      router.push({ name: 'showuser', params: { id: item.id } })
    }

    return {
      elt,
      openItem,
      formattingDate,
      icons: {
        mdiAccountBox,
        mdiCarArrowLeft,
        mdiAccount,
        mdiPhone,
        mdiEmail,
        mdiCarEstate,
        mdiCarDoor,
        mdiCarSettings,
        mdiFileDocument,
        mdiSmokingOff,
      },
    }
  },
}
</script>

<style scoped>

</style>
